<template>
  <div class="main">
    <div class="deal">
      <div class="deal_bgc">
        <h2 class="textCenter">用户注册协议</h2>
        <p class="paragraph"><b>【审慎阅读】：</b></p>
        <p>
          尊敬的客户，欢迎您光临“云彩人资科研”。为了您能便利、快捷的尊享我们提供的产品与服务，您需与我们签署本协议。<b
            >您在点击同意注册之前，请务必审慎阅读、充分理解本协议中相关条款内容，其中包括：您的授权条款、与您约定免除或限制责任的条款、与您个人信息相关（包括定义、收集、存储、加工、披露等）的条款、与您约定法律适用和管辖的条款、其他以粗体下划线标识的重要条款。</b
          >
        </p>
        <p class="paragraph"><b>【特别提示】</b></p>
        <p
          ><b
            ><u
              >当您按照注册页面提示填写信息、查看本协议内容后，您在点击“同意”或“注册”或显示类似字眼的页面时，即表示您已充分阅读、理解并接受本协议的全部内容；若您注册成为云彩人资科研用户，则表示您对本协议的全部内容已充分阅读并认可和同意遵守。</u
            ></b
          ></p
        >
        <p
          >您须遵守中国法律、法规、规章及其他政府规范性文件规定，如有违反而造成任何法律后果，您将独立承担所有相应的法律责任；同时，我们有权立即中止或终止为您服务。</p
        >
        <p
          ><b><u>阅读本协议的过程中，如果您不同意本协议中任何条款约定，您应立即停止注册程序。</u></b
          >如您对协议内容有疑问，您可于工作时间致电客服热线4009303606咨询。</p
        >
        <p class="paragraph"
          >深圳市云彩人资科研有限公司（本协议中简称“本公司”或“我们”)及运营的“云彩人资科研”平台（本协议中简称“云彩人资科研”“本平台”）依据本协议的约定为云彩人资科研用户(本协议中简称“用户”或“您”)提供服务。<b>本协议在用户和本公司之间具有合同上的法律效力，适用于用户在云彩人资科研的全部活动。本协议内容包括本协议全部条款及云彩人资科研已经发布的或将来可能发布的各类规则、通告。所有规则、通告为本协议不可分割的一部分,与本协议具有同等法律效力。</b>云彩人资科研有权根据运营需要不定时地制定、修改本协议或各类规则，云彩人资科研将随时刊载并公告本协议及规则变更情况。经修订的协议、规则一经公布，立即自动生效。对于协议、规则生效之前注册的用户，若用户在新规则生效后继续使用云彩人资科研提供的各项服务，则表明用户已充分阅读并认可和同意遵守新的协议或规则。若用户拒绝接受新的协议和规则，用户应立即放弃或终止继续使用云彩人资科研提供的各项服务，但该用户应承担在云彩人资科研已经进行的交易下所应承担的任何法律责任，且应遵循该用户发生交易时有效的协议或规则内容。</p
        >
        <p class="paragraph"><b>一、用户</b></p>
        <p
          >1.1.
          如果您是企业用户，在企业管理员为您开通账号后，您可以通过手机号码和验证码进行登录。企业用户可以通过手机号码和验证码进行登录账号。</p
        >
        <p
          >1.2.
          如果您是个人用户，您在注册云彩人资科研平台时应具备中华人民共和国法律法规规定的与您的行为相适应的民事行为能力，能够依据法律规定和本协议约定独立承担相应的法律责任。如您未满18周岁，请您在监护人陪同下仔细阅读并充分理解本协议，在征得监护人的同意后使用我们的服务。您应该使用真实有效的信息注册本平台。</p
        >
        <p
          ><b
            >1.3.
            用户在注册使用本平台服务时应按要求提供真实、最新、有效及完整的资料，并且授予本平台基于提供产品服务的目的，对用户提供的资料及数据信息拥有无偿使用的权利。同时，用户有义务维持并更新注册的资料，确保其为真实、最新、有效及完整的资料。如因用户未及时更新基本资料，导致服务无法提供或提供时发生任何错误，用户不得将此作为取消交易或承担义务的理由，本平台亦不承担任何责任，所有后果应由用户自行承担。</b
          ></p
        >
        <p
          ><b
            >1.4.
            用户提供任何错误、虚假、过时或不完整的资料，或者本公司依其独立判断怀疑资料为错误、虚假、过时或不完整的，本公司有权中止或终止用户在本平台的注册，并拒绝用户使用本平台服务的部分或全部功能。在此情况下，本公司不承担任何责任，用户同意承担因此所产生的直接或间接的任何支出或损失。如因此而给本公司及本平台造成损失的，用户还应予以赔偿。</b
          ></p
        >
        <p
          ><b>
            1.5.
            用户务必妥善保管用户的终端及账户、验证码和/或身份要素信息，并对利用该账户和验证码/身份信息所进行的一切活动负全部责任。用户若发现任何非法使用用户帐号或安全漏洞的情况，应立即通告本平台。除非有法律规定或司法裁定，否则，用户账户不得以任何方式转让、赠与或继承（与账户相关的财产权益除外）。
          </b></p
        >
        <p class="paragraph"><b>二、使用规范</b></p>
        <p
          >2.1.
          用户充分了解并同意，本公司仅为用户提供服务平台，用户应自行对利用服务从事的所有行为及结果承担责任。</p
        >
        <p
          >2.2.
          用户在本平台发布信息或者利用本平台的服务时，必须符合中国有关法律法规，不得在本平台上或者利用本平台的服务制作、复制、发布、传播以下信息：</p
        >
        <p>（1）违反宪法确定的基本原则的；</p>
        <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
        <p>（3）损害国家荣誉和利益的；</p>
        <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
        <p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
        <p>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
        <p>（7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</p>
        <p>（8）侮辱或者诽谤他人，侵害他人合法权益的；</p>
        <p>（9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；</p>
        <p>（10）以非法民间组织名义活动的；</p>
        <p>（11）含有法律、行政法规禁止的其他内容的。</p>
        <p>2.3. 用户不得利用本平台的服务从事以下活动：</p>
        <p>（1）未经允许，进入计算机信息网络或者使用计算机信息网络资源的；</p>
        <p>（2）未经允许，对计算机信息网络功能进行删除、修改、增加或破译的；</p>
        <p
          >（3）未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改、增加或破译的；</p
        >
        <p>（4）故意制作、传播计算机病毒等破坏性程序的；</p>
        <p>（5）其他危害计算机信息网络安全的行为。</p>
        <p>2.4. 用户不得以任何方式干扰本平台的服务。</p>
        <p
          >2.5.
          用户须对利用本平台账号或本服务传送信息的真实性、合法性、安全性、无害性、准确性、有效性等全权负责，用户所传播的信息相关的任何法律责任由用户自行承担。</p
        >
        <p class="paragraph"><b>三、服务内容及收费</b></p>
        <p
          ><b>3.1. 本平台可为用户提供以下服务：工作状态（如入职、离职等）查询、</b
          >薪金收支及查询、薪金（劳务报酬）发放及查询<b
            >等，具体详情以本平台当时提供的服务内容为准，服务形式是否有偿以本平台规则和用户与本公司签署的协议为准，此外用户应自行承担因使用第三方服务所产生的第三方费用，包括但不限于上网流量费、通信服务费、银行、支付机构等第三方机构收取的费用等。</b
          ></p
        >
        <p
          >3.2.
          工作状态查询：指个人用户注册本平台账户时，即授权本公司向与用户建立劳动关系、劳务关系、承包关系的主体（本文中简称“劳务派遣单位）获取个人用户的联络电话、身份证信息，以及在劳务派遣单位的入离职记录及请假（如有）、休假（如有）记录等，本公司根据劳务派遣提供的数据，在本平台向个人用户进行展示。个人用户需要知悉，本平台是根据劳务派遣提供的数据进行展示，本平台不会对数据内容进行加工，故本平台不对数据的真实性与准确性负责，如个人用户对数据有异议的，请与劳务派遣沟通修正。</p
        >
        <p
          >3.3.
          薪金收支及查询：指用户注册本平台账户时，即授权本公司向劳务派遣获取用户的姓名、联络电话、身份证信息、银行卡信息，以及应收薪资和服务费（如有），实收薪金（劳务报酬）和服务费（如有），社保、个税代扣代缴等数据，本公司根据劳务派遣提供的数据，在本平台向用户进行展示。用户可在本平台中进行应收薪资、服务费（如有）及实收薪资、服务费(如有)等的查询，向劳务派遣发起实收薪资、服务费（如有）的提取等操作。<b
            >用户需要知悉，本平台是根据劳务派遣提供的数据进行展示，本平台不会对数据内容进行加工，故本平台不对数据的真实性与准确性负责，如用户对数据有异议的，请与劳务派遣沟通修正；同时，本公司不提供任何形式的资金存放及划付服务，所有对应的资金存放、划付均由银行机构或第三方支付机构提供，如用户对资金存放、划付有异议，请与资金存放及划付所在的银行机构或第三方支付机构咨询。</b
          ></p
        >
        <p
          >3.4.
          薪金（劳务报酬）发放及查询：指企业用户注册本平台账户时，即授权本公司向企业用户获取其提供的薪资（劳务报酬）数据发放明细，包括但不限于个人姓名、身份证信息、银行卡信息、联系电话，以及应收薪资（劳务报酬）和服务费（如有），实收薪金（劳务报酬）和服务费（如有）等数据，本公司根据企业用户提供的数据，在本平台向企业用户进行展示。企业用户可通过本平台接入的银行、支付机构等第三方服务机构进行薪金（劳务报酬）、服务费（如有）的发放，并在本平台或提供薪金（劳务报酬）发放的第三方服务机构进行费用查询等操作。<b
            >企业用户需知悉，本平台仅做资金数据展示和记录，且是根据您单位提供的数据进行展示和记录，本平台不会对数据内容进行
            任何加工，故本平台不对数据的真实性、完整性、准确性、合法性、有效性负责，如您对数据有异议的，请与提供薪金（劳务报酬）发放的第三方服务机构进行沟通修正；同时，本公司不提供任何形式的资金存放及划付服务或其他任何与资金结算等有关的操作，所有对应的资金存放、划付、结算等操作均由银行机构或第三方支付机构提供，如用户对资金存放、划付、结算等操作有异议，请与资金存放及划付所在的银行机构或第三方支付机构咨询。</b
          ></p
        >
        <p
          ><b
            >3.5.
            其他：本公司会根据业务发展在本平台不时更新可提供的服务，本公司会尽可能详尽的介绍服务内容及所涉及的收费标准，用户需仔细阅读相关的介绍及提示。一旦用户选择服务，将视同用户对完全接受服务内容及收费标准。3.5.
            其他：本公司会根据业务发展在本平台不时更新可提供的服务，本公司会尽可能详尽的介绍服务内容及所涉及的收费标准，用户需仔细阅读相关的介绍及提示。一旦用户选择服务，将视同用户对完全接受服务内容及收费标准。</b
          ></p
        >
        <p class="paragraph"><b>四、账户安全及管理</b></p>
        <p
          ><b
            >4.1.
            用户有责任确保账户、密码、手势的机密安全。用户将对利用该账户及密码所进行的一切行动及言论，负完全的责任，并同意以下事项：</b
          ></p
        >
        <p
          >4.1.1.
          用户不得对其他任何人泄露账户或密码，亦不可使用其他任何人的账户或密码。因黑客、病毒或用户的保管疏忽等非本公司原因导致账号遭他人非法使用的，本公司不承担任何责任。</p
        >
        <p
          >4.1.2.
          用户的账号及密码是识别用户的指令，用户确认，使用账号和密码登录后在本平台的一切行为均代表用户本人。用户的账户操作所产生的电子信息记录均为用户本人行为的有效凭据，用户本人应对这些行为承担相应的法律责任。</p
        >
        <p
          ><b
            >4.1.3.
            用户承诺其向本平台所提交的任何包括但不限于划付、提取、结算等指令和推送的任何数据的真实性、完整性、准确性、合法性、有效性负责；如因该类指令或数据信息不准确、不真实、不完整、不合法、无效而可能导致本平台、本公司、用户等相关方损失的，由指令发出方自行承担一切相关责任；</b
          ></p
        >
        <p>4.1.4. 冒用他人账户及密码的，本公司保留追究实际使用人连带责任的权利。</p>
        <p
          >4.2.
          用户如发现第三人未经合法授权的情形冒用或盗用本人账户及密码，应立即以有效方式通知本公司并要求暂停相关服务，否则由此产生的一切责任由用户本人承担。同时，用户理解本公司对用户的请求采取行动需要合理期限，在此之前，本公司对第三人使用该服务所导致的损失不承担任何责任。</p
        >
        <p
          >4.3.
          用户决定不再使用本平台账户时，应首先结清对本公司的应付款项，并向本公司申请关闭该账户，经本公司审核同意后可正式关闭账户。用户同意：一旦申请关闭账户后将会导致用户数据删除，关闭账户后，本公司将无法再为用户提供数据查询服务。</p
        >
        <p
          >4.4.
          用户死亡或被宣告死亡的，其在本协议项下的各项权利义务由其继承人承担。若用户丧失全部或部分民事权利能力或民事行为能力，其在本协议项下的各项权利义务由其监护人代为行使。</p
        >
        <p
          >4.5.
          用户同意，基于运行和交易安全的需要，本公司可以暂时中止、限制、新增、改变本平台的全部或部分功能。在任何功能减少、增加或者变化时，只要用户仍然使用本平台，表示仍然同意本协议或者变更后的协议。</p
        >
        <p
          >4.6.
          本公司有权基于单方独立判断，在认为可能发生安全风险情形时，无需进行事先通知即有权中止提供用户服务；在安全风险确认后，本公司有权立即暂停、关闭用户账户，且无需对用户或任何第三方承担任何责任。前述情形包括但不限于：</p
        >
        <p>4.6.1. 用户提供的个人资料不具有真实性、有效性或完整性；</p>
        <p>4.6.2. 发现用户行为有违法之虞时；</p>
        <p>4.6.3. 用户账户涉嫌洗钱、传销、被冒用或其他有风险之情形；</p>
        <p>4.6.4. 用户已经违反本协议中约定的；</p>
        <p>4.6.5. 用户在使用本平台收费服务时未按规定支付相应的服务费用。</p>
        <p
          >4.7.
          用户同意，账户的暂停、中止或终止不代表用户责任的终止，用户仍应对使用本平台服务期间的行为承担可能的违约或损害赔偿责任，同时本公司仍可保有用户的相关信息。</p
        >
        <p class="paragraph"><b>五、用户信息与隐私保护</b></p>
        <p
          >5.1.
          本公司对用户信息予以严格保密，用户同意并确认，在下述情形下，本公司可以基于下述阐明之特定目的收集用户的注册信息、个人信息、行为信息、交易信息等：</p
        >
        <p
          >5.1.1.
          用户有义务在注册时根据注册申请的要求提供自己的真实个人信息（包括但不限于姓名、身份证号、联系电话以及地址等），并保证诸如电子邮件地址、联系电话、联系地址、邮政编码等内容的有效性、安全性和及时更新，以便本平台为用户提供服务并与用户进行及时、有效的联系。用户应承担因通过这些联系方式无法与用户取得联系而导致的任何损失或增加任何费用等不利后果。</p
        >
        <p
          >5.1.2.
          本公司会收集用户在本平台上的操作日志、所在位置、手机IMEI码等信息，本公司利用这些信息来保障用户账户安排，进行有关本平台之其他使用用户的兴趣及行为的内部研究，更好地向用户和本平台的其他用户提供更好的服务。</p
        >
        <p
          >5.2.
          用户同意并确认，对于用户依据本协议而提供的和本公司为提供本平台服务所需而收集的用户个人信息和资料，本公司有权按照本协议的约定进行使用或者披露。</p
        >
        <p
          >5.2.1.
          本公司基于履行有关协议、解决争议、调停纠纷、保障用户信息安全、提升用户体验、数据统计与分析等目的，用户同意授权本公司在符合法律规定的情况下可向必要的第三方披露用户的个人信息。</p
        >
        <p
          >5.2.2.
          本公司有义务根据有关法律、法规、规章及其他政府规范性文件的要求向司法机关和政府部门提供用户的个人资料及交易信息。</p
        >
        <p
          >5.2.3.
          在用户未能按照与本公司签订的包括但不限于本协议等其他法律文本的约定履行自己应尽的义务时，本公司有权将用户信息编入本平台黑名单。</p
        >
        <p
          >5.2.4.
          本公司采用行业标准惯例以保护用户的个人信息和资料，但由于技术限制、黑客攻击、病毒攻击等或用户个人原因导致用户个人信息和资料的泄露，本公司不承担相关的责任。</p
        >
        <p>5.3. 更多关于用户信息与隐私保护的内容，请查看寻工鸟隐私政策。</p>
        <p class="paragraph"><b>六、涉及第三方网站或客户端</b></p>
        <p
          >6.1.
          本平台的内容可能涉及由第三方所有、控制或运营的其它网站或客户端，含有访问第三方网站或客户端的链接，本公司会尽力要求第三方所提供的服务合法合规，给用户提供多样化且优惠的服务，但本公司无法保证第三方网站或客户端上信息的真实性和有效性。</p
        >
        <p>6.2. 本平台对所有第三方网站或客户端的隐私保护措施不承担任何责任。</p>
        <p
          >6.3.
          用户在使用第三方网站或客户端时，应遵守第三方网站或客户端的相关协议及使用规则。用户根据自身情况选择使用第三方服务，如有疑问或纠纷，须与第三方进行沟通解决，本公司不承担任何责任。</p
        >
        <p class="paragraph"><b>七、违约责任</b></p>
        <p
          >7.1.
          当用户的行为涉嫌违反法律法规或违反本协议约定的，本公司有权采取相应措施，包括但不限于直接屏蔽、删除侵权信息，或中止、终止提供服务。如使本公司遭受任何损失的（包括但不限于受到第三方的索赔、受到行政管理部门的处罚等），用户还应当赔偿或补偿本公司遭受的损失及（或）发生的费用，包括调查取证费、诉讼费、律师费、差旅费等费用。</p
        >
        <p
          >7.2.
          由于用户违反本协议，或违反其在本平台上签订的其它协议或文件，或由于用户使用本平台服务违反了任何法律或侵害第三方权利而导致任何第三方向本公司提出任何赔偿要求（包括律师费用），用户应对本公司给予全额补偿。</p
        >
        <p class="paragraph"><b>八、免责条款</b></p>
        <p
          >8.1.
          本公司及本平台对所提供的服务将尽力维护其安全性及方便性，但由于互联网服务的特殊性，非本公司主观过错或重大过失，本公司及本平台对服务中出现的中断、信息删除或储存失败等，不承担任何责任。</p
        >
        <p
          >8.2.
          用户同意，基于互联网的特殊性，本公司不保证本平台服务不会中断，也不保证服务的及时性和/或安全性。若因系统相关状况无法正常运作，使用户无法使用任何本平台服务或使用任何本平台服务时受到任何影响时，本公司对用户或第三方不负任何责任，前述状况包括但不限于：</p
        >
        <p>（1） 本平台系统停机维护期间。</p>
        <p>（2） 电信设备出现故障不能进行数据传输的。</p>
        <p
          >（3）
          由于黑客攻击、网络供应商技术调整或故障、网站升级、银行方面的问题等原因而造成的本平台服务中断或延迟。</p
        >
        <p
          >（4）
          因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成本平台系统障碍不能执行业务的。</p
        >
        <p
          >8.3.
          因用户的过错导致的任何损失，该过错包括但不限于：决策失误、操作不当、遗忘或泄露密码、密码被他人破解、用户使用的操作系统被第三方侵入、用户委托他人代理操作而造成的损失。</p
        >
        <p
          >8.4.
          在任何情况下，用户使用本平台服务过程中涉及由本平台的合作方或第三方提供相关服务的，服务内容由该合作方或第三方负责。用户享用相关服务时产生的争议、纠纷及损失，本公司不承担任何责任。</p
        >
        <p class="paragraph"><b>九、知识产权</b></p>
        <p
          >9.1.
          本公司对本平台内所有内容享有知识产权（包括但不限于商标权、专利权、著作权、商业秘密等），本平台所有内容，包括但不限于图片、档案、资讯、资料、平台架构、平台画面的安排、网页设计、应用程序、源代码、应用程序编程接口及相关著作权等，非经本公司书面同意，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表本平台程序或内容。</p
        >
        <p
          >9.2.
          非经本公司授权同意及法律规定，不得使用属于本平台上述所有内容。本平台所有的产品、技术及程序等信息内容均由本公司享有知识产权或被授权使用；未经本公司授权许可，任何其他组织或个人不得擅自使用（包括但不限于复制、复印、仿造、出售、转售、访问、或以其他方式加以利用等）。否则，本公司将依法追究其法律责任。</p
        >
        <p
          >9.3.
          用户承诺必须依法尊重本公司的知识产权，否则，本公司有权立即终止向用户提供服务，并依法追究其法律责任，要求用户赔偿本公司一切损失。</p
        >
        <p class="paragraph"><b>十、通知及送达</b></p>
        <p
          ><b
            >本协议项下的通知如以公示方式作出，一经在本平台公示即视为已经送达。除此之外，其他向用户发布的具有专属性的通知将由本平台向用户注册时或注册后向本平台提供的电子邮箱、手机号码发送，一经发送即视为已经送达。请您密切关注电子邮箱中的邮件和信息及手机中的短信信息。</b
          ></p
        >
        <p class="paragraph"><b>十一、法律适用和争议解决</b></p>
        <p
          >11.1.
          本协议的订立、变更、执行和解释，以及与本协议有关的争议解决，均应适用中华人民共和国法律。如与本协议有关的某一特定事项没有法律规定或规定不明确，则应参照通用的商业惯例和行业惯例处理。</p
        >
        <p
          >11.2.
          如因本协议或因本平台服务所引起或与其有关的任何争议，如协商不成，应向本公司所在地有管辖权的法院起诉。争议期间，各方仍应继续履行本协议未涉争议的条款义务。</p
        >
        <p class="paragraph"><b>十二、其他</b></p>
        <p
          >12.1.
          本协议自用户同意勾选或网上签署并成功注册成为本平台用户之日起生效，除非本公司终止本服务协议或者用户丧失本平台用户资格，否则本服务协议始终有效。本服务协议终止并不免除用户根据本服务协议或其他有关协议、规则所应承担的义务和责任。</p
        >
        <p>12.2. 本服务协议部分条款被认定为无效时，不影响本服务协议其他条款的效力。</p>
        <p>12.3. 本协议版本于2022年12月8日发布。</p>
        <p
          >12.4.
          本协议版本同时适用移动端和网页端，因移动端与网页端设备的特性差异，部分内容仅能适用移动端（例：手机IMEI码）或网页端，除此之外均可同时适用。</p
        >
        <p>12.5. 本公司对本服务协议在法律规定的范围内享有最终的解释权。</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup></script>
<style lang="less" scoped>
  .main {
    width: 100%;
    border-bottom: 10px solid #fff;
  }
  .deal {
    width: 1080px;
    margin: 24px auto 37px;
  }
  .deal_bgc {
    background-color: '#fff';
    box-sizing: border-box;
    padding: 40px 60px;
    line-height: 30px;
    font-size: 15px;
    text-align: justify;
    border: 2px solid #ebeced;
  }
  .textCenter {
    text-align: center;
  }
  .textRight {
    text-align: right;
  }
  .paragraph {
    margin-top: 30px;
  }
</style>
